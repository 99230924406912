<!--
    利用方法
    <TextInput
            divClass=""
            inputId=""
            inputClass=""
            inputName=""
            inputType=""
            :readonly=""
            labelClass=""
            labelName=""
            size=""
            v-model=""
            :error=""
    >
    </TextInput>
-->
<template>
    <div :class="divClass">
        <label :class="['label-class',labelClass]" :for="inputId">
            {{labelName}}
        </label>
        <input :type="inputType" :id="inputId"
        :class="['input-class',size,inputClass]" :name="inputName" :readonly="isReadonly"
        :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
        >
        <p v-if="error" class="error-message">{{ error }}</p>
    </div>
</template>

<script>
export default{
    name: 'TextInput',
    props:{
        divClass: String,
        inputId: String,
        inputClass: String,
        inputName: String,
        inputType:String,
        readonly: {
            type: Boolean,
            default: false
        },
        labelClass: String,
        labelName: String,
        size: String,
        error: {
            type: String,
            default: '',
        },
        modelValue: String
    },
    computed:{
        sizeClass(){
            return `input--${this.size}`;
        }
    },
    data(){
        return{
            isReadonly: false
        }
    },
    watch:{
        readonly(newVal) {
            this.isReadonly = newVal;
        },
    }
}
</script>

<style scoped>

    .input-class{
        border: 1px solid var(--silver);
        border-radius: 4px;
    }

    .label-class{
      font-size: 0.8rem;
      color: var(--dark-gray);
    }

    .error-message {
    color: red;
    font-size: 0.8em;
    margin-bottom: 0px;
    margin-top: 1px;
    padding-left: 2px;
    }
</style>