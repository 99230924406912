<template>
    <div>
      <h1>遷移先ページ</h1>
      <p>QRコードから遷移してきました。</p>
      <TextInput/>
      <ButtonBase>登録</ButtonBase>
      <h1>{{ custId }}</h1>
    </div>
  </template>
<script>
import { useRoute } from 'vue-router';
import {axios, apiHeader, apiUrl} from '@/constants.js';
import TextInput from '@/components/base/TextInput.vue';
import ButtonBase from '@/components/base/ButtonBase.vue';

  export default {
    name: 'CustDeviceSetting',
    components:{
        TextInput,
        ButtonBase
    },
    mounted(){
        const route = useRoute();
        this.custId = route.params.custId;
    },
    data(){
        return{
            custId:null,
        }
    },
    method:{
        async insCustDevice(){
            try {
                // 非同期処理を行う例（APIからデータを取得する場合など）
                const response = await axios.post(`${apiUrl}/require-custdevice`, {
                    custId: this.custId
                },
                {
                    headers:apiHeader
                }
                );
                // 成功ならトークン保存して遷移
                if (response.status == 200){
                    alert('登録に成功しました');
                    window.location.reload();
                }
            }
            catch (error) {
                console.error('Error fetching data:', error);
                alert('エラーが発生しました')
            }
        },
    }
  };
  </script>