<template>
    <div class="edit-block">
        <div class="device-info">
            <label class="device-label col-6"><strong>端末名</strong></label>
            <p class="device-data col-6">{{ deviceMqttData.name }}</p>
        </div>
        <div class="device-info">
            <label class="device-label col-6"><strong>型式</strong></label>
            <p class="device-data col-6">{{ deviceMqttData.deviceType }}</p>
        </div>
        <div class="device-info">
            <label class="device-label col-6"><strong>バッテリー</strong></label>
            <p class="device-data col-6"></p>
        </div>
        <div class="device-info">
            <label class="device-label col-6"><strong>最終アラート</strong></label>
            <p class="device-data col-6">{{ deviceMqttData.alertName }}</p>
        </div>
        <div class="device-info">
            <label class="device-label col-6"><strong>最終アラート時刻</strong></label>
            <p class="device-data col-6">{{ deviceMqttData.deteTime }}</p>
        </div>
        <!-- GPSの時だけ -->
        <div class="device-info">
            <label class="device-label col-6"><strong>24時間移動距離</strong></label>
            <p class="device-data col-6"></p>
        </div>
    </div>

    <ButtonBase color="cyan" btnClass="" @click="closeModal" >戻る</ButtonBase>
</template>
<script>
    import ButtonBase from '@/components/base/ButtonBase.vue';
    import {axios, apiHeader, apiUrl} from '@/constants.js';

    export default {
        name: 'DeviceMqttDetail',
        components: {
            ButtonBase,
        },
        props: {
            isVisible:{
                type: Boolean,
                default: false
            },
            custDeviceId:Number,
        },
        data(){
            return{
                deviceMqttData:[]
            }
        },
        mounted(){
            this.searchDeviceMqttData();
        },
        methods:{
            closeModal(){
                this.$emit('update:isVisible', false)
            },
            async searchDeviceMqttData(){
                try {
                    // 非同期処理を行う例（APIからデータを取得する場合など）
                    const response = await axios.post(`${apiUrl}/mqtt-detail`, {
                        custDeviceId: this.custDeviceId
                    },
                    {
                        headers:apiHeader
                    }
                    );
                    // 成功ならトークン保存して遷移
                    if (response.status == 200){
                        this.deviceMqttData = response.data.data;
                    }
                }
                catch (error) {
                    console.error('Error fetching data:', error);
                    alert('エラーが発生しました')
                }
            }
        }
    }
</script>

<style scoped>
.device-data{
    margin-inline: auto;
    margin-block: 0px;
    /* margin-block: 0px; */
}
.device-label{
    margin-bottom: 0.2rem;
}
.device-info {
    margin-bottom: 0.5rem;
}
</style>