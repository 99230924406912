import { createRouter, createWebHistory } from 'vue-router';
import DashbordHome from '../views/Home.vue';
import DeviceList from '../views/DeviceList.vue';
import CustLogin from '../views/CustLogin.vue';
import UserList from '@/views/UserList.vue';
import DeviceDetail from '@/views/DeviceDetail.vue';
import CustDeviceSetting from '@/views/CustDeviceSetting.vue';
import CustUserRegist from '@/views/CustUserRegist.vue';
import SettingQR from '@/components/ui/SettingQR.vue';
import PasswordReset from '@/views/PasswordReset.vue';

const routes = [
  { path: '/', component: DashbordHome },
  { path: '/device-list', component: DeviceList },
  { path: '/login', name: 'tenant', component: CustLogin },
  { path: '/reset', name: 'reset', component: PasswordReset },
  { path: '/user-list', component: UserList },
  { path: '/device-detail/:custDeviceId', name: 'device-detail', component: DeviceDetail , props: true },
  { path: '/custdevice-setting/:custId', name: 'CustDeviceSetting', component: CustDeviceSetting,},
  { path: '/password-regist', name: 'definRegist', component: CustUserRegist,},
  // 細かい部分が確定したら修正
  {
    path: '/qr',
    name: 'SettingQR',
    component: SettingQR,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;