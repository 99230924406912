<template>
    <div>
      <h1>Radix側 端末紐づけ作業用</h1>
      <qrcode :value="qrValue" :size="200" />
      <p>このQRコードをスキャンしてください。</p>
    </div>
  </template>

  <script>
  import QRCode from 'vue-qrcode';
  export default {
    components: {
        // 小文字でコンポーネント名を登録（サンプルに合わせてみた）
        qrcode: QRCode,
    },
    props:{
        custId:String
    },
    data() {
      return {
        // ココにカスタマーIDを追加
        qrValue: `${window.location.href}/custdevice-setting/${this.custId}`
      };
    },
  };
  </script>

<style>
</style>